import * as React from "react";
import Layout from "src/components/layout";

const PageComponent = ({ copy, pageTitle, location, lang }) => {
  return (
    <Layout pageTitle={pageTitle} location={location} lang={lang}>
      <section className="inner-content mx-auto my-20">
        <iframe
          title="video"
          loading="lazy"
          className="wistia_embed mx-auto w-2/3"
          src="//fast.wistia.net/embed/iframe/bmbj9hensh?videoFoam=true"
          allowFullScreen
          style={{ aspectRatio: "16/9" }}
        ></iframe>

        <p className="w-2/3 mx-auto mt-10 leading-7">{copy.text}</p>

        <dl className="w-2/3 mx-auto">
          {copy.list.map((item, key) => (
            <div key={key} className="mt-4 mb-8">
              <dt className="text-blue font-bold">{item.t}</dt>
              <dd>{item.d}</dd>
            </div>
          ))}
        </dl>
      </section>
    </Layout>
  );
};

export default PageComponent;
