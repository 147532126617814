import * as React from "react";
import PageComponent from "src/components/pages/learn/who-needs-life-insurance";

const OurTeamPage = ({ location }) => {
  const copy = {
    text: `Life insurance protects against financial loss that may be incurred at the death of the insured. Here are some needs for the protection that life insurance provides:`,
    list: [
      {
        t: `Income Replacement`,
        d: `If a person who works and creates earned income to support others, passes away during the income earning years, a loss to those dependents would be created. Life Insurance can replace the future income earning power cut short by an untimely death.`,
      },
      {
        t: `Wealth Preservation`,
        d: `An individual who has accumulated wealth over a lifetime often has a need for extra cash payable at death. In the US, the Estate Tax, which is as high as 40% of assets transferred at death, may create a very important need for cash at death.`,
      },
      {
        t: `Business Succession Planning/ Family Inheritance Equalization`,
        d: `Many times a family business makes up the bulk of a family’s wealth. How do parents plan for inheritance in a fair way if some children are in the business and others are not? Cash available at the death of the business owner may equalize the inheritance so that both peace in the family and the business are preserved.`,
      },
      {
        t: `Key Person Insurance`,
        d: `Businesses don’t make money, people do. The death of a key employee or partner may wreak havoc on a successful business. Cash available at the death of the key employee can help pay for new talent to replace the lost talent.`,
      },
      {
        t: `Buy/Sell Insurance`,
        d: `Would you like to be in business with your late partner’s widow’s second husband’s attorney? Without proper buy/sell planning, and the cash to carry out the plan, such a nightmare may become your reality.`,
      },
      {
        t: `Charitable Legacy`,
        d: `Charitable giving is very noble. How does one assure that a charity dear to one’s heart will receive continued support even after a donor’s passing? Life Insurance can be the perfect solution.`,
      },
    ],
  };
  return (
    <PageComponent
      lang="en"
      location={location}
      copy={copy}
      pageTitle="Who Needs Life Insurance?"
    />
  );
};

export default OurTeamPage;
